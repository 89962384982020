var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', [_c('div', {
    staticClass: "mb-1"
  }, [_c('strong', {
    staticClass: "text-dark"
  }, [_c('a', {
    attrs: {
      "href": "#"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.goBack($event);
      }
    }
  }, [_vm._v(" Kembali ")])])]), _c('b-card', [_c('h2', {
    staticClass: "mb-2"
  }, [_c('strong', [_vm._v("Rasionalisasi Kedinasan")])]), _c('p', [_vm._v(" Berdasarkan rumus excel yang sudah ditetapkan, rasionalisasi Kedinasan ditentukan oleh sub kategori dalam paket kedinasan, yang mana harus memiliki "), _c('strong', [_vm._v("Sub Kategori")]), _vm._v(": ")]), _c('ol', {
    staticClass: "mb-1"
  }, [_c('li', [_c('strong', [_vm._v("Tes Seleksi Kompetensi Dasar (Tes SKD)")])]), _c('li', [_c('strong', [_vm._v("Tes Psikotes")])])]), _c('p', [_vm._v(" Yang mana dari setiap sub kategori diatas harus memiliki ujian-ujian yang sudah dimasukan kedalamnya. Silahkan lihat bagan dibawah ini ")]), _c('section', {
    staticClass: "mb-1"
  }, [_c('img', {
    staticClass: "w-100 cursor-pointer",
    attrs: {
      "src": _vm.flowImageUrl,
      "alt": "Flow Bintara"
    },
    on: {
      "click": _vm.viewDetail
    }
  })]), _c('p', [_vm._v(" Dari gambar diatas dapat kita peroleh informasi bahwa ujian-ujian yang dimasukan kedalam "), _c('strong', [_vm._v("Sub Kategori SKD")]), _vm._v(", harus ditentukan "), _c('strong', [_c('i', [_vm._v("passing grade bobotnya")])]), _vm._v(" nya disetiap ujian di saat menentuan jadwalnya. Sedangkan ujian-ujian yang dimasukan kedalam "), _c('strong', [_vm._v("Sub Kategori Psikotes")]), _vm._v(", harus ditentukan bobot persentase nya di tab "), _c('strong', [_vm._v("Rasionalisasi")]), _vm._v(" di detail Paket. Pengaturan seperti ini dibuat agar setiap hasil pengerjaan Tryout Kedinasan, memiliki hasil berdasarkan "), _c('strong', [_vm._v("Sub Kategori SKD")]), _vm._v(" dan "), _c('strong', [_vm._v("Sub Kategori Psikotes")]), _vm._v(". ")]), _c('p', [_vm._v("Langkah yang harus dilakukan ialah:")]), _c('ol', [_c('li', {
    staticStyle: {
      "margin-bottom": "10px"
    }
  }, [_vm._v(" Pastikan di detail Paket Kedinasan, pada tab "), _c('strong', [_vm._v("Detail")]), _vm._v(" dan bagian "), _c('strong', [_vm._v("Tipe Rasionalisasi")]), _vm._v(" nya terpilih "), _c('strong', [_vm._v("Kedinasan.")])]), _c('li', {
    staticStyle: {
      "margin-bottom": "10px"
    }
  }, [_vm._v(" Pastikan di detail Paket Kedinasan, pada tab "), _c('strong', [_vm._v("Ujian / Subtest")]), _vm._v(" terdapat Sub Kategori "), _c('strong', [_vm._v("Seleksi Kompetensi Dasar")]), _vm._v(" dan "), _c('strong', [_vm._v("Psikotes")]), _vm._v(". ")]), _c('li', {
    staticStyle: {
      "margin-bottom": "10px"
    }
  }, [_vm._v(" Kemudian pastikan dalam setiap pembuatan "), _c('strong', [_vm._v("Jadwal Paket Kedinasan")]), _vm._v(", bagian ujian-ujian yang termasuk kedalam "), _c('strong', [_vm._v("Sub Kategori Seleksi Kompetensi Dasar")]), _vm._v(" sudah ditentukan "), _c('strong', [_c('i', [_vm._v("passing grade")])]), _vm._v(" nya.Dan untuk ujian-ujian di "), _c('strong', [_vm._v("Sub Kategori SKD")]), _vm._v(", pastikan harus ditentukan "), _c('i', [_vm._v("passing grade bobot nya")]), _vm._v(" di tab Rasionalisasi. ")]), _c('li', {
    staticStyle: {
      "margin-bottom": "10px"
    }
  }, [_vm._v(" Pastikan "), _c('strong', [_vm._v("Sub Kategori Psikotest")]), _vm._v(" ditandai opsi “Gunakan Passing Grade dari pembobotan rasinalisasi” sebagai tanda bahwa semua hasil ujian dengan kategori "), _c('strong', [_vm._v("Psikotest")]), _vm._v(", akan diproses dengan rumus dari bobot rasionalisasi kedinasan. ")]), _c('li', {
    staticStyle: {
      "margin-bottom": "10px"
    }
  }, [_vm._v(" Lalu pastikan untuk ujian-ujian yang termasuk kedalam "), _c('strong', [_vm._v("Sub Kategori Psikotes")]), _vm._v(", ditentukan "), _c('strong', [_vm._v("bobot persentase")]), _vm._v(" nya di hhalaman detail paket, di tab "), _c('strong', [_vm._v("Rasionalisasi")]), _vm._v(". ")])])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }