<template>
  <section>
    <b-card>
      <header class="mb-2">
        <p>
          Rasionalisasi Mandiri tidak seperti rasionalisasi yang lain yang
          mengharuskan mengatur <strong>bobot persentase</strong> di ujian
          ataupun <strong>sub kategorinya</strong>. Pada rasionalisasi ini,
          setiap paket harus terdiri dari <strong>2 Sub Kategori</strong>:
        </p>
      </header>
      <ol>
        <li>TPA</li>
        <li>
          Saintek / Soshum
          <i
            >(tergantung paket nya apakah mandiri saintek atau mandiri
            soshum)</i
          >
        </li>
      </ol>
      <p>
        Pastikan pada tab <strong>Detail</strong> di
        <strong>detail paket</strong>, bagian
        <strong>Tipe Rasionalisasi</strong> nya terpilih
        <strong>Mandiri</strong>.
      </p>
      <p>
        Setelah dipastikan terdapat 2 sub kategori diatas, admin bebas
        menentukan ujian-ujian mana yang akan dimasukan ke Sub Kategori. Berikut
        contoh Alur Rasionalisasi Mandiri:
      </p>
      <section class="mb-1">
        <img
          :src="flowImageUrl"
          class="w-100 cursor-pointer"
          @click="viewDetail(0)"
          alt="Flow Mandiri"
        />
      </section>
      <p class="mb-1">
        Secara penilaian yang harus diperhatikan pertama ialah pastikan semua
        jurusan di universitas sudah ditentukan
        <strong><i>persentase passing grade</i></strong> nya. Kemudian kita akan
        ambil contoh nilai siswa dibawah ini
      </p>
      <section class="mb-1">
        <img
          :src="sampleImage1"
          class="w-100 cursor-pointer"
          @click="viewDetail(1)"
          alt="Sample Data MAndiri"
        />
      </section>
      <p>
        Terlihat gambar diatas terdapat hasil siswa yang dibagi berdasarkan
        <strong>Sub Kategori</strong> nya. Dalam
        <strong>Sub Kategori TPA</strong>, semua soal di dalam ujian-ujian
        dengan <strong>Sub Kategori TPA</strong> di kalkulasikan
        <strong
          >jumlah soal, jumlah benar, jumlah salah dan jumlah kosong</strong
        >
        nya. Kemudian akan dihitung dengan rumus yang sudah ditentukan sistem:
      </p>
      <p class="mb-1">
        <strong
          >Nilai Akhir Sub Kategori = ( (Jmlh Benar * 4) - Jmlh Salah ) * 100 /
          ( Jmlh Soal * 4 )</strong
        >
      </p>
      <p class="mb-1">
        Maka terlihat di kolom <strong><i>PG Total ( 75.97 )</i></strong> yang
        merupakan hasil nilai akhir tryout mandiri yang nantinya nilai tersebut
        akan dibandingkan dengan passing grade dari jurusan pilihannya seperti
        gambar dibawah ini
      </p>
      <section class="mb-1">
        <img
          :src="sampleImage2"
          class="w-100 cursor-pointer"
          @click="viewDetail(2)"
          alt="Sample Data Mandiri"
        />
      </section>
    </b-card>
  </section>
</template>
<script>
import "viewerjs/dist/viewer.css";
import VueViewer from "v-viewer";
import Vue from "vue";
Vue.use(VueViewer);
import { BCard } from "bootstrap-vue";
export default {
  components: {
    BCard,
  },
  data: () => ({
    flowImageUrl: `${process.env.VUE_APP_API_FILE}/upload/assets/mandiri1.png`,
    sampleImage1: `${process.env.VUE_APP_API_FILE}/upload/assets/mandiri2.png`,
    sampleImage2: `${process.env.VUE_APP_API_FILE}/upload/assets/mandiri3.png`,
  }),
  methods: {
    goBack() {
      if (this.$route.query?.p) {
        const paketId = this.$route.query?.p;
        this.$router.push(
          `/master-admin/paket-tryout/detail/${paketId}?active_tab=rasionalisasi`
        );
      } else {
        this.$router.go(-1);
      }
    },
    viewDetail(index = 0) {
      const selectedImage =
        index == 0
          ? this.flowImageUrl
          : index == 1
          ? this.sampleImage1
          : this.sampleImage2;
      this.$viewerApi({
        images: [selectedImage],
      });
    },
  },
};
</script>
