var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', [_c('div', {
    staticClass: "mb-1"
  }, [_c('strong', {
    staticClass: "text-dark"
  }, [_c('a', {
    attrs: {
      "href": "#"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.goBack($event);
      }
    }
  }, [_vm._v(" Kembali ")])])]), _c('b-card', [_c('h2', {
    staticClass: "mb-2"
  }, [_c('strong', [_vm._v("Rasionalisasi Bintara")])]), _c('p', [_vm._v(" Berdasarkan rumus yang sudah disepakati di file excel, sistem rasionalisasi bintara ditentukan oleh sub kategori paket bintara dan passing grade nya. Secara ideal rumus rasionalisasi bintara, paket bintara harus terdiri dari 2 sub kategori: ")]), _c('ol', {
    staticClass: "mb-1"
  }, [_c('li', [_c('strong', [_vm._v("Tes Psikotes")])]), _c('li', [_c('strong', [_vm._v("Tes Akademik")])])]), _c('p', [_vm._v(" Dari 2 sub kategori tersebut harus ditentukan passing grade masing-masing sub kategori, karena sistem ranking hasil tryout bintara akan memunculkan 2 versi, ranking versi "), _c('b', [_vm._v("Tes Psikotes")]), _vm._v(" dan versi "), _c('b', [_vm._v("Tes Akademik")]), _vm._v(". Silahkan lihat gambaran rasionalisasi bintara dibawah ini: ")]), _c('section', {
    staticClass: "mb-1"
  }, [_c('img', {
    staticClass: "w-100 cursor-pointer",
    attrs: {
      "src": _vm.flowImageUrl,
      "alt": "Flow Bintara"
    },
    on: {
      "click": _vm.viewDetail
    }
  })]), _c('p', [_vm._v(" Dari gambar tersebut terlihat didalam setiap sub kategori, terdapat ujian-ujian yang sudah ditentukan di tab "), _c('strong', [_vm._v("Ujian")]), _vm._v(" di detail "), _c('strong', [_vm._v("Paket Tryout")]), _vm._v(". Di setiap ujian dalam 1 sub kategori juga harus ditentukan bobot persentase nya untuk mendapatkan hasil nilai sesuai dengan rumus rasionalisasi Bintara. ")]), _c('p', [_vm._v("Langkah yang harus dilakukan ialah:")]), _c('ol', [_c('li', {
    staticStyle: {
      "margin-bottom": "10px"
    }
  }, [_c('strong', [_vm._v("Admin")]), _vm._v(" membuka menu "), _c('strong', [_vm._v("Paket Tryout > Bintara > Detail")])]), _c('li', {
    staticStyle: {
      "margin-bottom": "10px"
    }
  }, [_vm._v(" Pastikan pada tab "), _c('strong', [_vm._v("Detail")]), _vm._v(", bagian opsi "), _c('strong', [_vm._v("Tipe Rasionalisasi")]), _vm._v(" terpilih opsi "), _c('strong', [_vm._v("Bintara")])]), _c('li', {
    staticStyle: {
      "margin-bottom": "10px"
    }
  }, [_vm._v(" Pastikan pada tab "), _c('strong', [_vm._v("Ujian")]), _vm._v(", terdapat 2 Sub Kategori dengan nama "), _c('strong', [_vm._v("Tes Psikotes")]), _vm._v(" dan "), _c('strong', [_vm._v("Tes Akademik")]), _vm._v(". ")]), _c('li', {
    staticStyle: {
      "margin-bottom": "10px"
    }
  }, [_vm._v(" Pada tab "), _c('strong', [_vm._v("Ujian")]), _vm._v(", silahkan masukan ujian-ujian kedalam masing-masing sub kategori. ")]), _c('li', {
    staticStyle: {
      "margin-bottom": "10px"
    }
  }, [_vm._v(" Setelah itu, klik tab "), _c('strong', [_vm._v("Rasionalisasi")]), _vm._v(", dan tentukan passing grade untuk masing-masing sub kategori. Tentukan juga bobot persentase untuk masing-masing ujian di setiap Sub Kategori. ")])])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }