var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', [_c('div', {
    staticClass: "mb-1"
  }, [_c('strong', {
    staticClass: "text-dark"
  }, [_c('a', {
    attrs: {
      "href": "#"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.goBack($event);
      }
    }
  }, [_vm._v(" Kembali ")])])]), _c('b-card', [_c('h2', {
    staticClass: "mb-2"
  }, [_c('strong', [_vm._v("Rasionalisasi UTBK SNBT")])]), _c('p', [_vm._v(" Rasionalisasi UTBK SNBT berbeda dari rasionalisasi lainnya, bisa dilihat dari gambar dibawah ini: ")]), _c('section', {
    staticClass: "mb-1"
  }, [_c('img', {
    staticClass: "w-100 cursor-pointer",
    attrs: {
      "src": _vm.flowImageUrl,
      "alt": "Flow UTBK SNBT"
    },
    on: {
      "click": _vm.viewDetail
    }
  })]), _c('p', [_vm._v(" Dari gambar diatas dapat dilihat kita harus memastikan "), _c('strong', [_c('i', [_vm._v("passing grade, daya tampung, dan jumlah peminat")])]), _vm._v(" di setiap jurusan harus ditentukan terlebih dahulu. Lalu Admin harus mengatur "), _c('strong', [_c('i', [_vm._v("bobot persentase")])]), _vm._v(" di setiap "), _c('strong', [_vm._v("Sub Kategori")]), _vm._v(" di paket tersebut. ")]), _c('p', [_vm._v(" Lalu terdapat settingan yg agak banyak, yaitu mengatur "), _c('strong', [_c('i', [_vm._v("bobot nilai ujian-ujian di setiap jurusan")])]), _vm._v(". ")]), _c('p', [_vm._v(" Dan jangan lupa bagian "), _c('strong', [_vm._v("Tipe Rasionalisasi")]), _vm._v(" di paket tersebut terpilih opsi UTBK. Maka jika semua langkah tersebut sudah dilakukan, admin bisa membuat jadwal untuk paket tersebut. ")])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }