var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', [_c(_vm.currentPage, {
    tag: "component"
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }