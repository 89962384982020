var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', [_c('b-card', [_c('header', {
    staticClass: "mb-2"
  }, [_c('p', [_vm._v(" Rasionalisasi Mandiri tidak seperti rasionalisasi yang lain yang mengharuskan mengatur "), _c('strong', [_vm._v("bobot persentase")]), _vm._v(" di ujian ataupun "), _c('strong', [_vm._v("sub kategorinya")]), _vm._v(". Pada rasionalisasi ini, setiap paket harus terdiri dari "), _c('strong', [_vm._v("2 Sub Kategori")]), _vm._v(": ")])]), _c('ol', [_c('li', [_vm._v("TPA")]), _c('li', [_vm._v(" Saintek / Soshum "), _c('i', [_vm._v("(tergantung paket nya apakah mandiri saintek atau mandiri soshum)")])])]), _c('p', [_vm._v(" Pastikan pada tab "), _c('strong', [_vm._v("Detail")]), _vm._v(" di "), _c('strong', [_vm._v("detail paket")]), _vm._v(", bagian "), _c('strong', [_vm._v("Tipe Rasionalisasi")]), _vm._v(" nya terpilih "), _c('strong', [_vm._v("Mandiri")]), _vm._v(". ")]), _c('p', [_vm._v(" Setelah dipastikan terdapat 2 sub kategori diatas, admin bebas menentukan ujian-ujian mana yang akan dimasukan ke Sub Kategori. Berikut contoh Alur Rasionalisasi Mandiri: ")]), _c('section', {
    staticClass: "mb-1"
  }, [_c('img', {
    staticClass: "w-100 cursor-pointer",
    attrs: {
      "src": _vm.flowImageUrl,
      "alt": "Flow Mandiri"
    },
    on: {
      "click": function click($event) {
        return _vm.viewDetail(0);
      }
    }
  })]), _c('p', {
    staticClass: "mb-1"
  }, [_vm._v(" Secara penilaian yang harus diperhatikan pertama ialah pastikan semua jurusan di universitas sudah ditentukan "), _c('strong', [_c('i', [_vm._v("persentase passing grade")])]), _vm._v(" nya. Kemudian kita akan ambil contoh nilai siswa dibawah ini ")]), _c('section', {
    staticClass: "mb-1"
  }, [_c('img', {
    staticClass: "w-100 cursor-pointer",
    attrs: {
      "src": _vm.sampleImage1,
      "alt": "Sample Data MAndiri"
    },
    on: {
      "click": function click($event) {
        return _vm.viewDetail(1);
      }
    }
  })]), _c('p', [_vm._v(" Terlihat gambar diatas terdapat hasil siswa yang dibagi berdasarkan "), _c('strong', [_vm._v("Sub Kategori")]), _vm._v(" nya. Dalam "), _c('strong', [_vm._v("Sub Kategori TPA")]), _vm._v(", semua soal di dalam ujian-ujian dengan "), _c('strong', [_vm._v("Sub Kategori TPA")]), _vm._v(" di kalkulasikan "), _c('strong', [_vm._v("jumlah soal, jumlah benar, jumlah salah dan jumlah kosong")]), _vm._v(" nya. Kemudian akan dihitung dengan rumus yang sudah ditentukan sistem: ")]), _c('p', {
    staticClass: "mb-1"
  }, [_c('strong', [_vm._v("Nilai Akhir Sub Kategori = ( (Jmlh Benar * 4) - Jmlh Salah ) * 100 / ( Jmlh Soal * 4 )")])]), _c('p', {
    staticClass: "mb-1"
  }, [_vm._v(" Maka terlihat di kolom "), _c('strong', [_c('i', [_vm._v("PG Total ( 75.97 )")])]), _vm._v(" yang merupakan hasil nilai akhir tryout mandiri yang nantinya nilai tersebut akan dibandingkan dengan passing grade dari jurusan pilihannya seperti gambar dibawah ini ")]), _c('section', {
    staticClass: "mb-1"
  }, [_c('img', {
    staticClass: "w-100 cursor-pointer",
    attrs: {
      "src": _vm.sampleImage2,
      "alt": "Sample Data Mandiri"
    },
    on: {
      "click": function click($event) {
        return _vm.viewDetail(2);
      }
    }
  })])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }