var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', [_c('div', {
    staticClass: "mb-1"
  }, [_c('strong', {
    staticClass: "text-dark"
  }, [_c('a', {
    attrs: {
      "href": "#"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.goBack($event);
      }
    }
  }, [_vm._v(" Kembali ")])])]), _c('b-card', [_c('h2', {
    staticClass: "mb-2"
  }, [_c('strong', [_vm._v("Rasionalisasi SMA")])]), _c('p', [_vm._v(" Rasionalisasi SMA secara sederhana hanya perlu menetapkan nilai penghitung untuk setiap hasil ujian siswa, yang nantinya akan menghasilkan nilai akhir sebuah ujian siswa. Secara rumus, "), _c('strong', [_vm._v("nilai penghitung ini akan dikali dengan hasil ujian siswa")]), _vm._v(". Secara default, "), _c('strong', [_vm._v("angka nilai penghitung / penentu hasil akhir ujian ialah "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("2.5")])])]), _c('p', [_vm._v(" Sebagai contoh terdapat nilai seorang siswa yang ditunjukan oleh tabel berikut: ")]), _c('table', {
    staticClass: "table table-bordered table-striped"
  }, [_c('tr', [_c('th', {
    staticClass: "text-center",
    staticStyle: {
      "width": "3%"
    }
  }, [_vm._v("No")]), _c('th', {
    staticClass: "text-center"
  }, [_vm._v("Mata Pelajaran")]), _c('th', {
    staticClass: "text-center"
  }, [_vm._v("Jumlah Benar")]), _c('th', {
    staticClass: "text-center"
  }, [_vm._v("Jumlah Salah")]), _c('th', {
    staticClass: "text-center"
  }, [_vm._v("Nilai (Jumlah Benar x Nilai Penghitung)")])]), _vm._l(_vm.sampleNilai, function (item, i) {
    return _c('tr', {
      key: i
    }, [_c('td', {
      staticClass: "text-center",
      staticStyle: {
        "width": "3%"
      }
    }, [_vm._v(_vm._s(i + 1))]), _c('td', {
      staticClass: "text-center"
    }, [_vm._v(_vm._s(item.mapel))]), _c('td', {
      staticClass: "text-center"
    }, [_vm._v(_vm._s(item.benar))]), _c('td', {
      staticClass: "text-center"
    }, [_vm._v(_vm._s(item.salah))]), _c('td', {
      staticClass: "text-center"
    }, [_vm._v(" " + _vm._s(parseInt(item.benar) * _vm.nilaiPenghitung) + " ")])]);
  })], 2)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }